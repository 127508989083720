import React from "react";

const ImageBackground = ({
  children,
  image,
  imageBackground,
  lightImageBackground,
}) => {
  return (
    <div
      className="container-fluid image-background"
      style={{
        background: imageBackground
          ? "#151821"
          : lightImageBackground
          ? "#ffffff"
          : `url(../img/images/${image}) center center / cover no-repeat `,
      }}
    >
      {children}
    </div>
  );
};

export default ImageBackground;
