import React from "react";

const IntelButton = ({ name, button, header, startPosition, buttonStyle, buttonTextStyle, onClick }) => {
  return (
    <button
      className={`${
        button === "light" ? "custom-transparent-button" : "custom-button"
      } ${header && "mobile-light-button"} ${
        startPosition && "mobile-button-start"
      }`}
      style={{ marginTop: header && 10, ...buttonStyle }}
      onClick={onClick}
    >
      <span style={buttonTextStyle}>{name}</span>
    </button>
  );
};

export default IntelButton;
