import React, { useState } from "react";
import jsonData from "../data/data.json";
import Footer from "./Footer/Footer";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    service: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://mbadvisory.co.in/contact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData),
      });
      const data = await response.json()
      if (data.status === 'success') {
        console.log(data);
        setSuccess(data.message);
        setFormData({ name: "", email: "", message: "", service: "" });
        const email = jsonData?.Contact?.email || "mbadvisory.co@gmail.com"; // Fallback email if not found
        const subject = "Thank you for contacting us";
        const body = `Hi,%0D%0A%0D%0AThank you for reaching out. We'll get back to you shortly regarding.%0D%0A%0D%0A-- Your Company Name`;

        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <div className="container-fluid" style={{ marginTop: 100 }}>
        <div className="container contactUs">
          <div className="row text-center">
            <h1>Contact Us</h1>
          </div>
          <div className="row text-center">
            <h4>{jsonData.Contact.content}</h4>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <h3
                style={{
                  textAlign: window.innerWidth >= 768 ? "start" : "center",
                }}
              >
                Get In Touch
              </h3>
              <div className="iconText">
                <img src="img/icons/location-pin (1).png" alt="Location Icon" />
                <h4>{jsonData.Contact.address}</h4>
              </div>
              <div className="iconText">
                <img src="img/icons/Group 172.png" alt="Website Icon" />
                <h4>{jsonData.Contact.website}</h4>
              </div>
              <div className="iconText">
                <img src="img/icons/Group 169.png" alt="Email Icon" />
                <h4>{jsonData.Contact.email}</h4>
              </div>
            </div>
            <div className="col-md-6">
              <h4 style={{ color: "red" }}>{error}</h4>
              <h4 style={{ color: "green" }}>{success}</h4>
              <form className="contact-form" onSubmit={handleSubmit}>
                <h2>Send a Message</h2>
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="email" className="form-label">
                    E-Mail Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="1"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="mb-2 dropDownContainer">
                  <label htmlFor="service" className="form-label">
                    Service
                  </label>
                  <select
                    className="form-select"
                    id="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select service</option>
                    {jsonData.Services.map((service, index) => (
                      <option key={index} value={service.title}>
                        {service.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="row text-center"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    type="submit"
                    className="btn btn-submit"
                    disabled={loading}
                  >
                    {loading ? <div className="loader"></div> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
