import React from "react";

const Content = ({ data, title, navigation }) => {
  return (
    <div className="footerContent">
      <h4>{title}</h4>
      {data?.map((d, index) => (
        <a href={navigation[index]}>
          <h5>{d}</h5>
        </a>
      ))}
    </div>
  );
};

export default Content;
