import React from "react";
import IntelButton from "../intelcomponents/IntelButton";

export const Header = ({header, mobileImage, headerButton}) => {
  return (
    <header id="header">
      <div className="intro" style={{  background: `url(../img/banner/${window.innerWidth >= 768 ? header?.image : mobileImage}) center center / cover`}}>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text">
                <div className="intro-header">
                  {header.title === 'Advisory' && <img src="img/logo.png" alt="logo" />}
                  <h1 style={{marginLeft: header.title === 'Advisory' && '15px'}}>
                    {header ? header.title : "Loading"}
                    <span></span>
                  </h1>
                </div>
                {header.subTitle && <h2>{header && header.subTitle}</h2>}
                <p style={{marginTop: header.title !== 'Advisory' && 20}}>{header ? header.paragraph : "Loading"}</p>
                <p style={{marginTop: header.title !== 'Advisory' && 20}}>{header.paragraph2 && header.paragraph2}</p>
                {headerButton && <a href="/contact"><IntelButton name={header.buttonText} header={true}/></a> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
