import React from "react";

const Timeline = ({ data }) => {
  return (
    <>
      <div className="timeline-mainDiv">
        <div className="timeline-circle" />
        <h5>{data.title}</h5>
      </div>
      <div className="timeline-content">
        <ul>
          <li>
            <p>
              {data.descTitle}: <span>{data.description}</span>
            </p>
          </li>
          <li>
            <p>
              Action: <span>{data.action}</span>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Timeline;
