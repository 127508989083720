import React from "react";
import Footer from "./Footer/Footer";

const Gallery = () => {
  const images = [
    "image1.png",
    "image2.png",
    "image3.png",
    "image4.png",
    "image5.png",
    "image6.png",
    "image7.png",
    "image8.png",
    "image9.png",
    "image10.png",
    "image11.png",
    "image12.png",
    'image13.png',
    "image14.png",
    // 'image15.png',
    'image16.png',
    "image17.png",
    "image18.png",
    "image19.png",
    "image20.png",
    "image21.png",
    "image22.png",
  ];

  return (
    <>
    <div className="container-fluid career" style={{ marginTop: 100 }}>
      <div className="container">
        {images?.map((img, index) => {
          if (index % 3 === 0) {
            return (
              <div className="row" key={`row-${index}`}>
                <div className="col-md-4" style={{ marginTop: 40 }}>
                  <img
                    src={`img/Gallery/${img}`}
                    style={{ width: "100%", height: 350 }}
                  />
                </div>
                {images?.slice(index + 1, index + 3).map((img, nextIndex) => (
                  <div className="col-md-4" style={{ marginTop: 40 }}>
                    <img
                      src={`img/Gallery/${img}`}
                      style={{ width: "100%", height: 350 }}
                    />
                  </div>
                ))}
              </div>
            );
          }
          return null;
        })}{" "}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Gallery;
