import React from "react";

const EligibilityAndDocumentation = ({ data, image }) => {
  return (
    <>
      <div
        className="col-md-6 "
        style={{
          backgroundSize: "40vh 40vh",
          backgroundColor: window.innerWidth <= 768 && "#D9D9D9",
          boxShadow: window.innerWidth <= 768 && "0px 4px 8px rgba(0, 0, 0, 0.2)",
          padding: window.innerWidth <= 768 && 30
        }}
      >
        <h3 className="headline" style={{textAlign: window.innerWidth <= 768 && 'center', marginBottom: window.innerWidth <= 768 && 40}}>{data.title}</h3>

        <div className="eligibilty listli">
          <ul>
            <li>
              <h5>
                {data?.eligibilty?.title}:{" "}
                <span>{data?.eligibilty?.paragraph}</span>
              </h5>
            </li>
            <li>
              <h5>{data?.documentation?.title}:</h5>
              <div className="subLi">
                {data?.documentation?.data?.map((d) => (
                  <>
                    <li>
                      <span>{d}</span>
                    </li>
                    <br />
                  </>
                ))}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-6">
        <img
          className="listImage hideContent"
          src={`img/images/${image}`}
          alt="image"
        />
      </div>
    </>
  );
};

export default EligibilityAndDocumentation;
