import React from 'react'
import jsonData from "../data/data.json";

const Contact = () => {
  return (
    <div className='contactUs'>
      <h3 style={{color: '#fff'}}>Contact Us</h3>
      <h5>{jsonData?.Contact?.address}</h5>
      <h6>{jsonData?.Contact?.email}</h6>
      {/* <h6>{jsonData?.Contact?.phone}</h6> */}
      <div className='social-media'>
        <img src='img/icons/instagram.png'/>
        <img src='img/icons/twitter.png'/>
        <img src='img/icons/facebook.png'/>
        <img src='img/icons/linkedin.png'/>
      </div>
    </div>
  )
}

export default Contact