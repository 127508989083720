import React from "react";

const Service = ({ data }) => {
  return (
    <>
    {window.innerWidth >= 768 ? <div className="col-md-3 col" style={{textWrap: 'wrap'}}>
      <img src={`img/icons/${data.icon}`} alt={data.title} style={{marginBottom: 10}} />
      <h5>{data.title}</h5>
      <h6 style={{fontSize: 14}}>{data.paragraph}</h6>
    </div>
    :
    <div className="listContentBlock listPadding sevice-mobile">
      <li>
        <p>{data.title}: <span>{data.paragraph}</span></p>
      </li>
    </div>
    }
    </>
  );
};

export default Service;
