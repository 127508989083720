import React from "react";

const ImageContent = ({ data, icon, image, displayImage, displayTitle, title, imageStyle }) => {
  return (
    <>
    {displayImage === 'left'  && <div className="col-md-6">
        <img className="listImage hideContent" src={`img/images/${image}`} alt="image" />
      </div>}
      <div
        className="col-md-6 listli"
        style={{
          background: `url(../img/icons/${icon}) center center no-repeat `,
          backgroundSize: "60vh 40vh",
          // width: '80%',
        }}
      >
         {displayTitle && <h3 className="headline" style={{marginBottom: 30}}>{title}</h3>}
        <ul>
          {data?.map((d) => (
            <>
              <li>
                <h5>
                  {d.title} <span>{d.paragraph}</span>
                </h5>
              </li>
            </>
          ))}
        </ul>
      </div>
      {displayImage === 'right'  && <div className="col-md-6">
        <img className={`listImage hideContent ${imageStyle && 'listImage2'}`} src={`img/images/${image}`} alt="image" />
      </div>}
    </>
  );
};

export default ImageContent;
