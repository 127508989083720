import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ImageContentWithTitle from "../intelcomponents/ImageContentWithTitle";
import ImageContentWithTitle1 from "../intelcomponents/ImageContentWithTitle1";
import IconContent from "../intelcomponents/cards/IconContent";

const ProjectLoan = () => {
  return (
    <Layout
      header={jsonData?.Header?.ProjectLoan}
      mobileImage="projectLoan.png"
    >
      <div className="container-fluid">
        <div
          className={`container ${
            window.innerWidth >= 768 && "mobile-padding"
          } projectLoan`}
        >
          <h3 className="headline" >{jsonData?.projectLoan?.title}</h3>
          {jsonData?.projectLoan?.loanData?.map((data, index) => (
            <div className="row">
              
                {window.innerWidth >= 768 ? (
                  <div
                  className={`row ${window.innerWidth >= 768 && "listSpacing"}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <ImageContentWithTitle
                    data={data?.data}
                    title={data?.title}
                    image={data?.image}
                    displayImage={index % 2 === 0 ? "right" : "left"}
                    liStyle={{ height: index === 1 ? '60vh' : index === 5 ? '40vh' : '45vh'}}
                  />
                  </div>
                ) : (
                  <ImageContentWithTitle1
                    data={data?.data}
                    title={data?.title}
                    image={data?.image}
                    displayImage={index % 2 === 0 ? "right" : "left"}
                    colNo={window.innerWidth >= 768 ? "6" : "12"}
                  />
                )}
              
            </div>
          ))}
        </div>
      </div>
      <div className="container-fluid BGPrimary">
      <div className="container mobile-padding content keyConsiderations">
          <div className="row content">
            <h3 className="headline">{jsonData?.projectLoan?.keyFactor?.title}</h3>
            <div className="row content" style={{marginBottom: window.innerWidth <= 768 && -20}}>
              {jsonData?.projectLoan?.keyFactor?.data?.map((d) => (
                <IconContent data={d} colSize="4" />
              ))}
            </div>
          </div>
        <div className="row keyConsiderations projectLoanContent">
              <h5>{jsonData?.projectLoan?.keyFactor?.content1}</h5>
            </div>
        <div className="row keyConsiderations projectLoanContent">
              <h5>{jsonData?.projectLoan?.keyFactor?.content2}</h5>
            </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProjectLoan;
