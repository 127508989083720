import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ImageBackground from "../intelcomponents/ImageBackground";
import IconContent from "../intelcomponents/cards/IconContent";
import ArrowContent from "../intelcomponents/ArrowContent";
import CardSection from "../intelcomponents/CardSection";
import Timeline from "../intelcomponents/Timeline";

const BankGaurantee = () => {
  return (
    <Layout
      header={jsonData?.Header?.BankGaurantee}
      mobileImage="bank-guarantee-mobile.png"
    >
      {window.innerWidth >= 768 ? (
        <div className="container-fluid">
          <div className="container mobile-padding bank-guarantee">
            <div className="row content">
              <h3 className="headline">
                {jsonData?.bankGuarantee?.creditLetter?.title}
              </h3>
              <h5 className="headline" style={{ marginTop: 20 }}>
                {jsonData?.bankGuarantee?.creditLetter?.subTitile}
              </h5>
              {jsonData?.bankGuarantee?.creditLetter?.steps.map(
                (step, index) => (
                  <ArrowContent step={step} index={index} />
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`container-fluid ${
              window.innerWidth <= 768 && "BGPrimary"
            }`}
          >
            <div className="container mobile-padding bank-guarantee bank-guarantee-mobile content">
              <h3 className="headline">
                {jsonData?.bankGuarantee?.creditLetter?.title}
              </h3>
              <h5
                className="headline"
                style={{ marginTop: 15, marginBottom: 35 }}
              >
                {jsonData?.bankGuarantee?.creditLetter?.subTitile}
              </h5>
              {jsonData?.bankGuarantee?.creditLetter?.steps?.map((step) => (
                <Timeline data={step} />
              ))}
            </div>
          </div>
          <ImageBackground image="bank-guarantee-BG.png">
            <div className="container mobile-padding bank-guarantee bank-guarantee-mobile content">
              <h3 className="headline">
                {jsonData?.bankGuarantee?.bankGuarantee?.title}
              </h3>
              <h5
                className="headline"
                style={{ marginTop: 15, marginBottom: 35 }}
              >
                {jsonData?.bankGuarantee?.bankGuarantee?.subTitile}
              </h5>
              {jsonData?.bankGuarantee?.bankGuarantee?.steps?.map((step) => (
                <Timeline data={step} />
              ))}
            </div>
          </ImageBackground>
        </>
      )}
      <ImageBackground
        image="real-world.png"
        lightImageBackground={window.innerWidth >= 768 ? false : true}
      >
        <div
          className={`container mobile-padding content keyConsiderations real-world ${
            window.innerWidth <= 768 && "real-world-mobille"
          }`}
        >
          <div className={`row ${window.innerWidth >= 768 && "content"}`}>
            <h3 className="headline">
              {jsonData?.bankGuarantee?.realWorld?.title}
            </h3>
            <div className="row content">
              {jsonData?.bankGuarantee?.realWorld?.data?.map((d, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className="row mobile-padding" key={`row-${index}`}>
                      <IconContent data={d} colSize="6" />
                      {jsonData?.bankGuarantee?.realWorld?.data
                        ?.slice(index + 1, index + 2)
                        .map((nextLoan) => (
                          <IconContent data={nextLoan} colSize="6" />
                        ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </ImageBackground>
      {window.innerWidth >= 768 && (
        <div className="container-fluid">
          <div className="container mobile-padding bank-guarantee">
            <div className="row content">
              <h3 className="headline">
                {jsonData?.bankGuarantee?.bankGuarantee?.title}
              </h3>
              <h5 className="headline" style={{ marginTop: 20 }}>
                {jsonData?.bankGuarantee?.bankGuarantee?.subTitile}
              </h5>
              {jsonData?.bankGuarantee?.bankGuarantee?.steps.map(
                (step, index) => (
                  <ArrowContent step={step} index={index} />
                )
              )}
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid bankBenefit"  style={{marginTop: window.innerWidth <= 768 && -70}}>
        <div className="container">
          <h3 className="headline">Benefits in Action</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div
          className="container bank-guarantee"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {window.innerWidth <= 768 ? <CardSection />
          :
          <img src="img/images/bankGuarantee_benefits.png" style={{width: '70%'}} />}
        </div>
      </div>
      <br />
      <div
        className={`container-fluid bank-guarantee-conclusion ${
          window.innerWidth <= 768 && "mobile-light-background"
        }`}
      >
        <div
          className={`container mobile-padding conclusion content ${
            window.innerWidth <= 768 && "mobile-dark"
          }`}
        >
          <h3 className="headline">Conclusion</h3>
          <h5 style={{ padding: "20px 0px", fontWeight: 200 }}>
          Letters of Credit and Bank Guarantees are critical tools in modern finance, allowing businesses to engage in secure and efficient transactions across borders. Understanding their applications and processes empowers businesses to leverage them effectively in various sectors.
          </h5>
        </div>
      </div>
    </Layout>
  );
};

export default BankGaurantee;
