import React from "react";

const IntelContent = ({ data }) => {
  return (
    <>
      <div className="col-md-6 col">
        <img src={`img/icons/${data.icon}`} alt={data.title} />
        <h3>{data.title}</h3>
        <h5>{data.paragraph}</h5>
      </div>
    </>
  );
};

export default IntelContent;
