import React from "react";

const AboutContent1 = ({ data, index, height }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <img src={`img/images/AboutUS${index + 1}.png`} style={{ ...height }} />
      </div>
      <div className="col-md-6">
        <h4 style={{ fontWeight: 400, lineHeight: 2, color: "#000" }}>
          <b>{index === 0 && `Manoj Bhalerao`}</b>
          {data}
        </h4>
      </div>
    </div>
  );
};

export default AboutContent1;
