import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ListContent from "../intelcomponents/cards/ListContent";
import IconContent from "../intelcomponents/cards/IconContent";
import ImageBackground from "../intelcomponents/ImageBackground";
import NumberListContent from "../intelcomponents/cards/NumberListContent";

const LeaseRental = () => {
  return (
    <Layout header={jsonData?.Header?.leaseRental} mobileImage="lease-rental-mobile.png">
      <div className="container-fluid">
        <div className="container mobile-padding realEstate">
          <div className="row content">
          <h3 className="headline" style={{textAlign: 'center', marginBottom: 40}}>Here’s a basic outline of what a proposal for an LRD loan typically includes:</h3>
            <h3 className="headline headlineStart">{jsonData?.leaseRental?.LRDLoan?.title}</h3>
            {window.innerWidth >= 768
              ? jsonData?.leaseRental?.LRDLoan?.data?.map((loan, index) => {
              if (index % 3 === 0) {
                return (
                  <div className="row" key={`row-${index}`}>
                    <ListContent data={loan} para2={true} para3={true} />
                    {jsonData?.leaseRental?.LRDLoan?.data
                      ?.slice(index + 1, index + 3)
                      .map((nextLoan, nextIndex) => (
                        <ListContent
                          key={`loan-${index + nextIndex + 1}`}
                          data={nextLoan}
                          para2={true}
                          para3={true}
                        />
                      ))}
                  </div>
                );
              }
              return null;
            }): jsonData?.leaseRental?.LRDLoan?.data?.map((loan, index) => (
              <NumberListContent data={loan} index={index} para2={true} para3={loan.paragraph3} />
            ))}
          </div>
        </div>
      </div>
      <ImageBackground image="leaseRentalKeyConsideration.png" imageBackground={window.innerWidth <= 768 ? true : false}>
        <div className="container mobile-padding content keyConsiderations">
          <div className="row content">
            <h3 className="headline">Key Considerations</h3>
            <div className="row content" style={{marginBottom: window.innerWidth <= 768 && -20}}>
              {jsonData?.leaseRental?.keyConsiderations?.data?.map((d) => (
                <IconContent data={d} colSize="4" />
              ))}
            </div>
          </div>
        <div className="row keyConsiderations">
              <h5 style={{fontWeight: 200}}>{jsonData?.leaseRental?.keyConsiderations?.content}</h5>
            </div>
        </div>
      </ImageBackground>
    </Layout>
  );
};

export default LeaseRental;
