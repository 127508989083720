import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ListContent from "../intelcomponents/cards/ListContent";
import ImageBackground from "../intelcomponents/ImageBackground";
import IconContent from "../intelcomponents/cards/IconContent";
import NumberListContent from "../intelcomponents/cards/NumberListContent";
import FlexIconContent from "../intelcomponents/cards/FlexIconContent";

const RealEstate = () => {
  return (
    <Layout
      header={jsonData?.Header?.realEstate}
      mobileImage="real-estate-mobile.png"
    >
      <div
        className={`container-fluid ${window.innerWidth <= 768 && "BGPrimary"}`}
      >
        <div
          className={`container mobile-padding realEstate ${
            window.innerWidth <= 768 && "realEstate-mobile"
          }`}
        >
          <div className="row content">
          <h3 className="headline" style={{textAlign: 'center', marginBottom: 40}}> Here's a quick overview:</h3>
            <h3 className="headline headlineStart" style={{ marginBottom: 20 }}>
              Types of Real Estate Investments
            </h3>
            {window.innerWidth >= 768
              ? jsonData?.realEstate?.types?.data?.map((loan, index) => {
                  if (index % 3 === 0) {
                    return (
                      <div className="row" key={`row-${index}`}>
                        <ListContent data={loan} para2={true} />
                        {/* Render next items until we hit the end of the row */}
                        {jsonData?.realEstate?.types?.data
                          ?.slice(index + 1, index + 3)
                          .map((nextLoan, nextIndex) => (
                            <ListContent
                              key={`loan-${index + nextIndex + 1}`}
                              data={nextLoan}
                              para2={true}
                            />
                          ))}
                      </div>
                    );
                  }
                  return null;
                })
              : jsonData?.realEstate?.types?.data?.map((loan, index) => (
                  <NumberListContent data={loan} index={index} para2={true} />
                ))}
          </div>
        </div>
      </div>
      <ImageBackground image="keyConsideration.png" lightImageBackground={window.innerWidth <= 768 ? true : false}>
        <div
          className={`container mobile-padding content keyConsiderations ${
            window.innerWidth <= 768 && "keyConsiderations-mobile"
          } `}
        >
          <div className="row content">
            <h3 className="headline">Key Considerations</h3>
            <div className="row content">
              {jsonData?.realEstate?.keyConsiderations?.map((d) => (
                <IconContent data={d} colSize="3" />
              ))}
            </div>
          </div>
        </div>
      </ImageBackground>
      <div className={`container-fluid ${window.innerWidth <= 768 && "BGPrimary"}`}>
        <div className="container mobile-padding strategies">
          <div className="row content">
            {window.innerWidth >= 768 ? (
              <>
                <h3 className="headline">Strategies</h3>
                <div className="row content">
                  {jsonData?.realEstate?.strategies?.map((d) => (
                    <IconContent data={d} colSize="3" />
                  ))}
                </div>
              </>
            ) : (
              <>
                <h3 className="headline mobileheadline">Strategies</h3>
                <div className="row content">
                  <FlexIconContent data={jsonData?.realEstate?.strategies} />
                </div>
              </>
            )}
            {/* <div className={`row strategies ${window.innerWidth <= 768 && "strategies-mobile"}`}>
              <h6>{jsonData?.realEstate?.strategiesContent}</h6>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RealEstate;
