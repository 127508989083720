import React from "react";
import Footer from "./Footer/Footer";
import jsonData from "../data/data.json";
import IntelButton from "../intelcomponents/IntelButton";
import ListContent from "../intelcomponents/cards/ListContent";

const Career = () => {
  const onClick = () => {
    const email = jsonData?.Contact?.email || "mbadvisory.co@gmail.com"; // Fallback email if not found
    const subject = "Thank you for contacting us";
    const body = `Hi,%0D%0A%0D%0AThank you for reaching out. We'll get back to you shortly regarding.%0D%0A%0D%0A-- Your Company Name`;

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };
  return (
    <>
      <div className="container-fluid career" style={{ marginTop: 100 }}>
        <div className="container">
          <div className="row text-center">
            <h1>{jsonData?.career?.title}</h1>
            <h4 style={{ textAlign: "start" }}>
              {jsonData?.career?.paragraph}
            </h4>
            <IntelButton
              name={jsonData?.career?.buttonText}
              buttonStyle={{ borderRadius: 15, padding: "10px 50px" }}
              buttonTextStyle={{ color: "#fff" }}
              onClick={onClick}
            />
          </div>
          <div className="row" style={{ padding: "40px 0px" }}>
            <div className="image-grid">
              <img
                src="img/images/career1.png"
                alt="Image 1"
                className="grid-image"
              />
              <img
                src="img/images/career2.png"
                alt="Image 2"
                className="grid-image"
              />
              <img
                src="img/images/career3.png"
                alt="Image 3"
                className="grid-image"
              />
              <img
                src="img/images/career4.png"
                alt="Image 4"
                className="grid-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container mobile-padding career">
          <div className="row content">
            <h3 className="headline" style={{ marginBottom: 40 }}>
              {jsonData?.career?.joinUs?.title}
            </h3>
            {jsonData?.career?.joinUs?.data?.map((loan, index) => {
              if (index % 3 === 0) {
                return (
                  <div className="row" key={`row-${index}`}>
                    <ListContent data={loan} />
                    {jsonData?.career?.joinUs?.data
                      ?.slice(index + 1, index + 3)
                      .map((nextLoan, nextIndex) => (
                        <ListContent
                          key={`loan-${index + nextIndex + 1}`}
                          data={nextLoan}
                        />
                      ))}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Career;
