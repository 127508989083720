import React from 'react'

const NumberListContent = ({data , index, para2, para3}) => {
  return (
    <div className='numberList' style={{marginTop: 30}}>
        <span>{`${index + 1}. ${data.title}`}</span>
        <ul>
        <li><span>{data.paragraph}</span></li>
        {para2 && <li><span style={{marginTop: 20}}>{data.paragraph2}</span></li>}
        {para3 && <li><span style={{marginTop: 20}}>{data.paragraph3}</span></li>}
        </ul>
    </div>
  )
}

export default NumberListContent