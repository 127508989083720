import React from "react";

const ArrowContent = ({step, index}) => {
  return (
    <div key={index} className="row step">
      <div className="col-md-3">
        <div className={`step-arrow ${step.color}`}>
          <span className="step-title">{step.title}</span>
          <div className="step-icon-div">
            <img
              src={`img/icons/${step.icon}`}
              alt={step.title}
              className="step-icon"
            />
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div className="step-content">
          <ul>
            <li>
              <p>
                {step.descTitle}: <span>{step.description}</span>
              </p>
            </li>
            <li>
              <p>
                Action: <span>{step.action}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ArrowContent;
