import React from "react";
import JsonData from "../data/data.json";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
            <img src="img/logo.png" alt="logo" />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="page-scroll">
                About Us
              </a>
            </li>
            <li className="dropdown">
              <a
                href="#services"
                className="page-scroll dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                {JsonData?.footer?.services?.data?.map((ser, index) => (
                  <li>
                    <a
                      href={JsonData?.footer?.services?.navigation[index]}
                      className="page-scroll"
                    >
                      {ser}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <a href="/gallery" className="page-scroll">
               Gallery
              </a>
            </li>
            <li>
              <a href="/contact" className="page-scroll">
                Contact Us
              </a>
            </li>
            <li>
              <a href="/career" className="page-scroll">
                Career
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
