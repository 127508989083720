import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import AboutContent from "../intelcomponents/cards/AboutContent";
import AboutContent1 from "../intelcomponents/cards/AboutContent1";

const AboutUS = () => {
  console.log(jsonData?.about);
  return (
    <Layout header={jsonData?.Header?.aboutus} mobileImage="about-mobile.png">
      <div className="container-fluid aboutUSNew">
        <div className="container">
          {jsonData?.about?.data?.map((data, index) => (
            <>
              <div
                className="row"
                style={{ display: "flex", alignItems: "center" }}
              >
                {window.innerWidth >= 768 ? (
                  <AboutContent
                    data={data}
                    index={index}
                    height={{
                      height:
                        index === 1
                          ? "45vh"
                          : index === 2
                          ? "55vh"
                          : index === 3
                          ? "50vh"
                          : "70vh",
                    }}
                  />
                ) : (
                  <AboutContent1
                    data={data}
                    index={index}
                    height={{
                      height:
                        index === 1
                          ? "45vh"
                          : index === 2
                          ? "55vh"
                          : index === 3
                          ? "50vh"
                          : "50vh",
                    }}
                  />
                )}
              </div>
              {index !== 0 && <br />}
              <br />
            </>
          ))}
          <h4 style={{ fontWeight: 400, lineHeight: 2, color: "#000" }}>
            A thought leader in his domain, Mr. Bhalerao is also recognized for
            his integrity, problem-solving capabilities, and result-oriented
            mindset. His expertise spans across industries, with a particular
            focus on construction finance, manufacturing, and MSMEs, making him
            an invaluable asset to companies seeking sustainable growth. With a
            vision to simplify the complexities of financial consulting and
            empower businesses, Manoj Bhalerao has become more than a
            consultant—he is a trusted advisor, a reliable partner, and a key
            enabler of success in the financial landscape. His legacy continues
            to inspire confidence, setting benchmarks for excellence in the
            industry.
          </h4>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUS;
