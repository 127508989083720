import React from "react";

const ListContent = ({data, para2, para3}) => {
  return (
    <div className="col-md-4 listContentBlock listPadding">
      {/* <ul style={{listStylePosition: 'outside'}}> */}
      <li>
        <span>{data.title}</span>
        <h5>{data.paragraph}</h5>
        {para2 && <h5 style={{marginTop: 10}}>{data.paragraph2}</h5>}
        {para3 && <h5 style={{marginTop: 10}}>{data.paragraph3}</h5>}
      </li>
      {/* </ul> */}
    </div>
  );
};

export default ListContent;
