import React from "react";

const ImageContentIcon = ({ image, data, imageSide, title, marginStyle }) => {
  return (
    <>
      {imageSide === "left" && window.innerWidth >= 768 && (
        <div className="col-md-4">
          <img
            className="listImage hideContent"
            src={`img/images/${image}`}
            alt="image"
          />
        </div>
      )}
      <div className="col-md-8">
        <h3 style={{ marginBottom: 20, ...marginStyle }} className="headline marginSideSpacing">
          {title}
        </h3>
        <div className="row">
          {data?.map((d, index) => {
            if (index % 2 === 0) {
              return (
                <div className="row" key={`row-${index}`} style={{marginLeft: 10}}>
                  <div className="col-md-6">
                    <img src={`img/icons/${d.icon}`} alt={d.title} />
                    <div className="dataContent">
                      <h4>{d.title}</h4>
                      <h5>{d.paragraph}</h5>
                    </div>
                  </div>
                  {/* <br/> */}
                  {data
                    ?.slice(index + 1, index + 2)
                    .map((nextLoan, nextIndex) => (
                      <>
                      <div className="col-md-6">
                        <img
                          src={`img/icons/${nextLoan.icon}`}
                          alt={nextLoan.title}
                        />
                        <div className="dataContent">
                          <h4>{nextLoan.title}</h4>
                          <h5>{nextLoan.paragraph}</h5>
                        </div>
                      </div>
                      {/* <br/> */}
                      </>
                    ))}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      {imageSide === "right" && window.innerWidth >= 768 && (
        <div className="col-md-4">
          <img
            className="listImage hideContent"
            src={`img/images/${image}`}
            alt="image"
          />
        </div>
      )}
    </>
  );
};

export default ImageContentIcon;
