import React from "react";

const ImageContentWithTitle = ({ data, icon, image, title }) => {
  return (
    <div className="row mobile-padding" style={{marginTop: 30}}>
      <div className="col-md-6">
        <img className="listImage1" src={`img/images/${image}`} alt="image" />
      </div>
      <div
        className="col-md-6"
        style={{
          background: `url(../img/icons/${icon}) center center no-repeat`,
          backgroundSize: "40vh 40vh",
        }}
      >
        <h3
          className="headline headlineStart"
          style={{ marginBottom: window.innerWidth <= 768 && 40 }}
        >
          {title}
        </h3>

        <div className="listli">
          <ul>
            {data?.map((d) => (
              <>
                <li>
                  <h5>
                    {d.title} <span>{d.paragraph}</span>
                  </h5>
                </li>
                <br />
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ImageContentWithTitle;
