import React from "react";

const FlexIconContent = ({ data, flag }) => {
  return data?.map((_, index) => {
    if (index % 2 === 0) {
      const img1 =
        flag === "dealer" && index === 2
          ? "collateral-mobile.png"
          : flag === "builder" && index === 0
          ? "financing-mobile.png"
          : flag === "builder" && index === 2
          ? "repayment1-mobile.png"
          : flag === "ChooseUs" && index === 0
          ? "brain-mobile.png"
          : data[index].icon;

      const img2 =
        flag === "dealer" && index + 1 === 1
          ? "repayment-mobile.png"
          : flag === "builder" && index + 1 === 1
          ? "colateral1-mobile.png"
          : flag === "builder" && index + 1 === 3
          ? "loan-mobile.png"
          : flag === "ChooseUs" && index + 1 === 3
          ? "quality-assurance-mobile.png"
          : data[index + 1].icon;

      return (
        <div
          key={index}
          style={{
            marginTop: flag !== "dealer" ? 0 : flag !== "builder" ? 0 : 10,
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              flex: 1,
              background:
                flag === "dealer" && index === 0
                  ? "#151821"
                  : flag === "ChooseUs" && index === 0
                  ? "#151821"
                  : flag === "dealer" && index === 2
                  ? "#FFC857"
                  : flag === "ChooseUs" && index === 2
                  ? "#FFC857"
                  : flag === "builder"
                  ? "#151821"
                  : "#151821",
            }}
            className={`flexContent ${
              flag === "dealer" && index === 0 && "dealer"
            } ${flag === "dealer" && index === 2 && "dealer1"} ${
              flag === "builder" && "builder"
            } ${flag === "ChooseUs" && index === 0 && "ChooseUsFlex"} ${
              flag === "ChooseUs" && index === 2 && "ChooseUsFlex1"
            } `}
          >
            <img src={`img/icons/${img1}`} alt={data[index].title} />
            <div
              className={`dataContent ${
                flag === "dealer" && index === 0 && "dealer"
              } ${flag === "dealer" && index === 2 && "dealer1"} ${
                flag === "builder" && "builder"
              } ${flag === "ChooseUs" && index === 0 && "ChooseUsFlex"} ${
                flag === "ChooseUs" && index === 2 && "ChooseUsFlex1"
              }`}
            >
              <h5>{data[index].title}</h5>
              <h6>{data[index].paragraph}</h6>
              {data[index].paragraph2 && (
                <h6 style={{ marginTop: 20 }}>{data[index].paragraph2}</h6>
              )}
            </div>
          </div>

          {data[index + 1] && (
            <div
              style={{
                flex: 1,
                background:
                  flag === "dealer" && index + 1 === 3
                    ? "#151821"
                    : flag === "dealer" && index + 1 === 1
                    ? "#FFC857"
                    : flag === "ChooseUs" && index + 1 === 3
                    ? "#151821"
                    : flag === "ChooseUs" && index + 1 === 1
                    ? "#FFC857"
                    : flag === "builder"
                    ? "#151821"
                    : "#151821",
              }}
              className={`flexContent ${
                flag === "dealer" && index + 1 === 3 && "dealer"
              } ${flag === "dealer" && index + 1 === 1 && "dealer1"} ${
                flag === "builder" && "builder"
              } ${flag === "ChooseUs" && index + 1 === 3 && "ChooseUsFlex"} ${
                flag === "ChooseUs" && index + 1 === 1 && "ChooseUsFlex1"
              }`}
            >
              <img src={`img/icons/${img2}`} alt={data[index + 1].title} />
              <div
                className={`dataContent contentPadding ${
                  flag === "dealer" && index + 1 === 3 && "dealer"
                } ${flag === "dealer" && index + 1 === 1 && "dealer1"} ${
                  flag === "builder" && "builder"
                } ${flag === "ChooseUs" && index + 1 === 3 && "ChooseUsFlex"} ${
                  flag === "ChooseUs" && index + 1 === 1 && "ChooseUsFlex1"
                }`}
              >
                <h5>{data[index + 1].title}</h5>
                <h6>{data[index + 1].paragraph}</h6>
                {data[index + 1].paragraph2 && (
                  <h6 style={{ marginTop: 20 }}>
                    {data[index + 1].paragraph2}
                  </h6>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  });
};

export default FlexIconContent;
