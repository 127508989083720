import React from "react";

const AboutContent = ({ data, index, height }) => {
  return (
    <>
      {index % 2 === 0 && (
        <div className="col-md-6">
          <img src={`img/images/AboutUs${index + 1}.png`} style={{...height}} />
        </div>
      )}
      <div className="col-md-6">
        <h4 style={{fontWeight: 400, lineHeight: 2, color: '#000'}}><b>{index === 0 && `Manoj Bhalerao`}</b>{data}</h4>
      </div>
      {index % 2 !== 0 && (
        <div className="col-md-6">
          <img src={`img/images/AboutUs${index + 1}.png`} style={{...height}} />
        </div>
      )}
    </>
  );
};

export default AboutContent;
