import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ImageContent from "../intelcomponents/cards/ImageContentList";
import ListLiContent from "../intelcomponents/cards/ListLiContent";
import ImageBackground from "../intelcomponents/ImageBackground";

const ArcAdvisory = () => {
  return (
    <Layout
      header={jsonData?.Header?.arcAdvisory}
      mobileImage="Artborad-mobile.png"
    >
      <div className="container-fluid">
        <div className="container mobile-padding arc">
          <div className="row content">
            {/* <h3 className="headline">{jsonData?.arcAdvisory?.ARCs?.title}</h3> */}
            <div
              className="row listSpacing"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageContent
                data={jsonData?.arcAdvisory?.ARCs?.data}
                title={jsonData?.arcAdvisory?.ARCs?.title}
                icon="coin.png"
                image="homeLoan.png"
                displayImage="right"
                displayTitle={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`container-fluid ${window.innerWidth <= 768 && "BGPrimary"}`}
      >
        <div
          className={`container mobile-padding arc ${
            window.innerWidth <= 768 && "content mobile-light"
          } `}
        >
          <div className="row">
            {/* <h3 className="headline">{jsonData?.arcAdvisory?.NCLT?.title}</h3> */}
            <div
              className="row listSpacing"
              style={{
                display: window.innerWidth >= 768 && "flex",
                alignItems: window.innerWidth >= 768 && "center",
              }}
            >
              <ImageContent
                data={jsonData?.arcAdvisory?.NCLT?.data}
                title={jsonData?.arcAdvisory?.NCLT?.title}
                icon="nclt.png"
                image="Artboard.png"
                displayImage="left"
                displayTitle={true}
                imageStyle={true}
              />
            </div>
          </div>
        </div>
      </div>
      <ImageBackground
        image={
          window.innerWidth >= 768 ? "Artboard2.png" : "Artboard-mobile.png"
        }
        imageBackground={window.innerWidth <= 768 ? false : true}
      >
        <div className="container mobile-padding arcList content">
          <h3 className="headline">
            {jsonData?.arcAdvisory?.interaction?.title}
          </h3>
          <div className="listliContent">
            <ListLiContent data={jsonData?.arcAdvisory?.interaction?.data} />
          </div>
        </div>
      </ImageBackground>
      <br />
      <ImageBackground
        image="Artboard1.png"
        lightImageBackground={window.innerWidth >= 768 ? false : true}
      >
        <div
          className={`container mobile-padding arcList content ${
            window.innerWidth <= 768 && "mobile-dark"
          }`}
          style={{
            marginBottom: window.innerWidth <= 768 && -100,
            padding: window.innerWidth >= 768 && "100px 0px",
          }}
        >
          <h3 className="headline">
            {jsonData?.arcAdvisory?.corporateCase?.title}
          </h3>
          <div
            className={`listliContent ${
              window.innerWidth <= 768 && "mobile-dark"
            }`}
          >
            <ListLiContent data={jsonData?.arcAdvisory?.corporateCase?.data} />
          </div>
        </div>
      </ImageBackground>
      <div className="container-fluid">
        <div className="container mobile-padding conclusion content">
          <h3 className="headline">Conclusion</h3>
          <h5 style={{ padding: "20px 0px" }}>
            {jsonData?.arcAdvisory?.Conclusion}
          </h5>
        </div>
      </div>
    </Layout>
  );
};

export default ArcAdvisory;
