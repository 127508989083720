import React from "react";

const Card = ({ data }) => {
  return (
    <div class="card" style={{boxShadow: window.innerWidth <= 768 && "0px 4px 8px rgba(0, 0, 0, 0.2)",}}>
      <div class="card-body">
        <div className="cardTitle">
          <h3>{data.title}</h3>
        </div>
        <div>
          <h5 style={{color: window.innerWidth <= 768 && '#AAAAAA', fontWeight: window.innerWidth <= 768 && 600 }}>{data.paragraph}</h5>
        </div>
      </div>
    </div>
  );
};

export default Card;
