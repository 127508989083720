import React from "react";

const IconContent = ({data, colSize}) => {
  return (
    <div className={`col-md-${colSize} marginSideSpacing`} style={{marginTop: 10}}>
      <img src={`img/icons/${data.icon}`} alt={data.title} />
      <div className="dataContent">
        <h5>{data.title}</h5>
        <h6>{data.paragraph}</h6>
        {data.paragraph2 && <h6 style={{marginTop: 20}}>{data.paragraph2}</h6>}
      </div>
    </div>
  );
};

export default IconContent;
