import React from "react";

const ListLiContent = ({ data }) => {
  return data?.map((d) => (
    <li>
      <span>{d}</span>
    </li>
  ));
};

export default ListLiContent;
