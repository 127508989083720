import React from "react";

const CardSection = () => {
  return (
    <div className="row bank-benefits">
      <div
        className="col-md-4"
        // style={{
        //   background: `radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
        //         radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
        //         radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
        //         radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
        //         linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
        //         linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
        //         linear-gradient(352deg, transparent 50%, #48abe0 25%, #48abe0 50%, transparent 50%, transparent 75%, #48abe0 75%)`,
        //   padding: 20,
        // }}
      >
        <div className="card">
          <div className="card-icon">
          <img src='img/icons/warning.png'/>
          </div>
          <h3>Risk Mitigation</h3>
          <p>
            Both instruments help reduce financial risks, fostering trust and
            facilitating smoother transactions.
          </p>
        </div>
      </div>
      <div
        className="col-md-4"
        // style={{
        //   borderLeft: "1px solid",
        //   borderBottom: "1px solid",
        //   borderRight: "1px solid",
        //   borderRadius: 20,
        //   padding: 20,
        // }}
      >
        <div className="card secondCard">
          <div className="card-icon">
          <img src='img/icons/creditibilty.png'/>
          </div>
          <h3>Enhanced Credibility</h3>
          <p>
            Utilizing LCs and BGs enhances the credibility of businesses in
            negotiations and transactions.
          </p>
        </div>
      </div>
      <div
        className="col-md-4"
        // style={{
        //   borderRight: "1px solid",
        //   borderTop: "1px solid",
        //   borderRadius: 20,
        //   padding: 20,
        // }}
      >
        <div className="card">
          <div className="card-icon">
            <img src='img/icons/funding.png'/>
          </div>
          <h3>Access to Funding</h3>
          <p>
            Companies can leverage these instruments to secure better financing
            terms or access to loans.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
