import React from 'react'
import { Header } from './header'
import Footer from './Footer/Footer'

const Layout = ({children, header, mobileImage, headerButton}) => {
  return (
    <>
    <Header header={header} mobileImage={mobileImage} headerButton={headerButton}/>
    {children}
    <Footer/>
    </>
  )
}

export default Layout