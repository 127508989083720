import React from "react";

const Testimonial = ({ data }) => {
  return (
    <div className="col-md-6" style={{marginTop: 20}}>
      <div class="card mainDiv"></div>
      <div class="card testimonialDataDiv">
        <div className="image-container text-center">
          <img src={`img/testimonials/${data.image}`} alt={data.name} />
        </div>
        <img
          src="img/icons/apostropicalLeft.png"
          alt={data.name}
          className="apostropical"
        />
        <h3>{data.name}</h3>
        <h6>{data.title}</h6>
        <h5>"{data.quote}"</h5>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "flex-end", marginRight:10 }}
        >
          <img
            src="img/icons/apostropicalRight.png"
            alt={data.name}
            className="apostropical"
            style={{ textAlign: "end" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
