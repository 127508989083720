import React from "react";

const ImageContentWithTitle = ({ data, icon, image, displayImage, title, liStyle, desktopColor, mobileColor }) => {
  return (
    <>
      {displayImage === "left" && (
        <div className="col-md-6">
          <img
            className="listImage hideContent"
            src={`img/images/${image}`}
            alt="image"
            style={{...liStyle}}
          />
        </div>
      )}
      <div
        className="col-md-6 "
        style={{
          background: `url(../img/icons/${icon}) center center no-repeat`,
          backgroundSize: "40vh 40vh",
        }}
      >
        <h4 className="headline headlineStart" style={{marginBottom: window.innerWidth <= 768 && 40, color: window.innerWidth <= 768 ? mobileColor : desktopColor}}>{title}</h4>

        <div className="listli">
        <ul>
          {data?.map((d) => (
            <>
              <li>
                <h5>
                  {d.title} <span>{d.paragraph}</span>
                </h5>
              </li>
              {/* <br /> */}
            </>
          ))}
        </ul>
        </div>
      </div>
      {displayImage === "right" && (
        <div className="col-md-6">
          <img
            className="listImage hideContent"
            src={`img/images/${image}`}
            alt="image"
            style={{...liStyle}}
          />
        </div>
      )}
    </>
  );
};

export default ImageContentWithTitle;
