import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ImageContent from "../intelcomponents/cards/ImageContentList";
import ImageContentWithTitle from "../intelcomponents/ImageContentWithTitle";
import IconContent from "../intelcomponents/cards/IconContent";
import EligibilityAndDocumentation from "./EligibilityAndDocumentation";
import Card from "../intelcomponents/cards/Card";
import FlexIconContent from "../intelcomponents/cards/FlexIconContent";

const InventoryFunding = () => {
  return (
    <Layout
      header={jsonData?.Header?.inventoryFunding}
      mobileImage="inventory-funding-mobile.png"
    >
      <div className="container-fluid">
        <div className="container mobile-padding arc">
          <div className="row content">
            <h3 className="headline" style={{textAlign: 'center', marginBottom: 40}}> Here's how it works for each:</h3>
            <div
              className={`row ${window.innerWidth >= 768 && "listSpacing"}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageContentWithTitle
                data={jsonData?.inventoryFunding?.reconstruction?.data}
                title={jsonData?.inventoryFunding?.reconstruction?.title}
                image="car-dealer.png"
                displayImage="right"
              />
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth >= 768 ? (
        <div className="container-fluid BGPrimary">
          <div className="container mobile-padding content keyConsiderations real-world">
            <div className="row">
              <h3 className="headline">
                {jsonData?.inventoryFunding?.dealerkeyFeature?.title}
              </h3>
              <div className="row content">
                {jsonData?.inventoryFunding?.dealerkeyFeature?.data?.map(
                  (d, index) => {
                    if (index % 4 === 0) {
                      return (
                        <div className="row" key={`row-${index}`}>
                          <IconContent data={d} colSize="3" />
                          {jsonData?.inventoryFunding?.dealerkeyFeature?.data
                            ?.slice(index + 1, index + 4)
                            .map((nextLoan) => (
                              <IconContent data={nextLoan} colSize="3" />
                            ))}
                        </div>
                      );
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`container-fluid ${
            window.innerWidth >= 768 && "mobile-padding"
          } real-world-mobile`}
        >
          <h3 className="headline">
            {jsonData?.inventoryFunding?.dealerkeyFeature?.title}
          </h3>
          <>
            <div className="row content">
              <FlexIconContent
                data={jsonData?.inventoryFunding?.dealerkeyFeature?.data}
                flag="dealer"
              />
            </div>
          </>
        </div>
      )}
      <div
        className={`container-fluid ${window.innerWidth <= 768 && "BGPrimary"}`}
      >
        <div
          className={`container mobile-padding arc ${
            window.innerWidth <= 768 && "mobile-light"
          }`}
        >
          <div className="row content">
            <div
              className={`row ${window.innerWidth >= 768 && "listSpacing"}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageContentWithTitle
                data={jsonData?.inventoryFunding?.builder?.data}
                title={jsonData?.inventoryFunding?.builder?.title}
                image="builder.png"
                displayImage="right"
                desktopColor='#000'
                mobileColor='#fff'
              />
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth >= 768 ? (
        <div className="container-fluid BGPrimary">
          <div className="container mobile-padding content keyConsiderations real-world">
            <div className="row">
              <h3 className="headline">
                {jsonData?.inventoryFunding?.builderkeyFeatures?.title}
              </h3>
              <div className="row content">
                {jsonData?.inventoryFunding?.builderkeyFeatures?.data?.map(
                  (d, index) => {
                    if (index % 4 === 0) {
                      return (
                        <div className="row" key={`row-${index}`}>
                          <IconContent data={d} colSize="3" />
                          {jsonData?.inventoryFunding?.builderkeyFeatures?.data
                            ?.slice(index + 1, index + 4)
                            .map((nextLoan) => (
                              <IconContent data={nextLoan} colSize="3" />
                            ))}
                        </div>
                      );
                    }
                    return null;
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`container-fluid ${
            window.innerWidth >= 768 && "mobile-padding"
          } real-world-mobile builder-mobile BGPrimary`}
        >
          <h3 className="headline">
            {jsonData?.inventoryFunding?.builderkeyFeatures?.title}
          </h3>
          <>
            <div className="row content">
              <FlexIconContent
                data={jsonData?.inventoryFunding?.builderkeyFeatures?.data}
                flag="builder"
              />
            </div>
          </>
        </div>
      )}
      <div className="container-fluid">
        <div className="container mobile-padding arc">
          <div className="row content">
            <div
              className={`row ${window.innerWidth >= 768 && "listSpacing"}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ImageContentWithTitle
                data={jsonData?.inventoryFunding?.petrol?.data}
                title={jsonData?.inventoryFunding?.petrol?.title}
                image="petrol-pump.png"
                displayImage="right"
                desktopColor='#000'
                mobileColor='#000'
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid BGPrimary">
        <div className="container mobile-padding content keyConsiderations real-world">
          <div className="row">
            <h3 className="headline">
              {jsonData?.inventoryFunding?.petrolkeyFeature?.title}
            </h3>
            <div className="row content">
              {jsonData?.inventoryFunding?.petrolkeyFeature?.data?.map(
                (d, index) => {
                  if (index % 3 === 0) {
                    return (
                      <div className="row" key={`row-${index}`}>
                        <IconContent data={d} colSize="4" />
                        {jsonData?.inventoryFunding?.petrolkeyFeature?.data
                          ?.slice(index + 1, index + 3)
                          .map((nextLoan) => (
                            <IconContent data={nextLoan} colSize="4" />
                          ))}
                      </div>
                    );
                  }
                  return null;
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container mobile-padding arc">
          <div className="row content">
            <div
              className="row listSpacing"
              style={{ display: "flex", alignItems: "center" }}
            >
              <EligibilityAndDocumentation
                image="eligibilityDocumentation.png"
                data={jsonData?.inventoryFunding?.commonEligibility}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`container-fluid ${
          window.innerWidth >= 768 && "BGPrimary"
        } `}
      >
        <div
          className={`container mobile-padding keyConsiderations ${
            window.innerWidth <= 768 && "mobile-dark"
          }`}
        >
          <div className="row content">
            <h3 className="headline">
              {jsonData?.inventoryFunding?.keyBenefits?.title}
            </h3>
            <div
              className={`row keyBenefits ${
                window.innerWidth <= 768 && "mobile-dark"
              }`}
            >
              {jsonData?.inventoryFunding?.keyBenefits?.data?.map(
                (d, index) => {
                  if (index % 3 === 0) {
                    return (
                      <div className="row" key={`row-${index}`}>
                        <div className="col-md-4">
                          <Card data={d} />
                        </div>
                        {jsonData?.inventoryFunding?.keyBenefits?.data
                          ?.slice(index + 1, index + 3)
                          .map((nextLoan) => (
                            <div className="col-md-4">
                              <Card data={nextLoan} />
                            </div>
                          ))}
                      </div>
                    );
                  }
                  return null;
                }
              )}
            </div>
            <p className="key-benefits">
              {jsonData?.inventoryFunding?.keyBenefits?.content}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InventoryFunding;
